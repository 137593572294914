import escapeHTML from 'escape-html';
import { Node, Text } from 'slate';
import { CustomElement, CustomText } from '.';

export const serialize = (nodes: Node[]) => {
  return nodes.map((n) => Node.string(n)).join('\n');
};

export const serializeHtml = (nodes: CustomElement[]) => {
  const handleLeaf = (node: CustomText) => {
    if (Text.isText(node)) {
      let string = escapeHTML(node.text);
      if (node.bold) {
        string = `<strong>${string}</strong>`;
      }
      if (node.italics) {
        string = `<em>${string}</em>`;
      }
      return string;
    }
  };

  const handleNode = (node: CustomElement) => {
    const children = node.children.map((n: any) => handleLeaf(n)).join('');
    switch (node.align) {
      case 'center':
        return <p className="text-center">{children}</p>;
      case 'justify':
        return <p className="text-justify">{children}</p>;
      case 'left':
        return <p className="text-left">{children}</p>;
      case 'right':
        return <p className="text-right">{children}</p>;
      default:
        return children;
    }
  };
  nodes.map((n) => handleNode(n)).join('');
};
