import { FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ThemeContext } from "../context/theme.context";
import { updateThemeUrl } from "../helpers/url.helper";

type FormProps = {
    onClose?: () => any
}
export const Form: FC<FormProps> = ({ onClose }) => {
    const state = useContext(ThemeContext);
    const list: { key: string, name: string }[] = JSON.parse(localStorage.getItem("themeList") || "[]")

    const { register, handleSubmit } = useForm();

    const [themeListOpen, setThemeListOpen] = useState(false)

    const submit = (data: any) => {
        state.setTheme(state, data.theme)
        updateThemeUrl(data.theme)
        if (onClose) {
            onClose()
        }

    }

    const close = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        if (state.theme && onClose) {
            onClose()
        }
    }

    return (
        <div className="form flex flex-col items-center pt-[25%]" onClick={close}>
            <form onClick={e => e.stopPropagation()} className="bg-[#282c34] flex flex-col gap-0.5 p-8 rounded-lg" onSubmit={handleSubmit(submit)}>
                <input
                    defaultValue={state.theme}
                    autoComplete="off"
                    placeholder="Theme"
                    className="rounded text-slate-300 bg-zinc-900 py-2 px-4"
                    {...register("theme", { required: true })} type="text" name="theme" />
                {/* <button>
                    Aléatoire
                </button> */}
                <button className="mt-4 p-2 border border-zinc-500 rounded-full" type="submit">
                    C'est parti
                </button>
            </form>
            {
                list.length > 0 &&
                <button onClick={() => { setThemeListOpen(!themeListOpen) }} className="z-40 bg-zinc-900 border-teal-600 mb-[-15px] border py-1 px-8 rounded-full text-teal-600 italic mt-4">Retrouver un précédent thème</button>
            }
            {themeListOpen &&
                <div className="max-w-2xl p-4 flex flex-wrap justify-center gap-2 bg-[#282c34]/50 mx-5 pt-7 pb-5 rounded-lg">
                    {
                        list.map(t => {
                            if (!t.key || !t.name) return <></>
                            return (
                                <button
                                    key={t.key}
                                    className="my-1 rounded-full py-1 px-8 border text-gray-300 border-teal-600"
                                    onClick={() => {
                                        state.setTheme(state, t.name)
                                        setThemeListOpen(false)
                                    }}>
                                    {t.name}
                                </button>
                            )
                        })
                    }
                </div>}
        </div>
    )
}