import React from "react";
import { updateThemeUrl } from "../helpers/url.helper";
import { ContextType } from "./models.context";

export const addThemeToLocaStorageList = (themeKey: string, themeName: string) => {
    const savedList = localStorage.getItem('themeList')
    const list: { key: string, name: string }[] = savedList ? JSON.parse(savedList) : []
    if (themeKey === "themeList") themeKey = themeKey + "_"
    if (!list.find(item => item.key === themeKey)) {
        list.push({
            key: themeKey,
            name: themeName
        })
    }
    localStorage.setItem('themeList', JSON.stringify(list))
}

const initialList = [
    'Everything is broken',
    'The floor is lava',
    'Elephant in the room',
    'A cat day',
    'Falling apart',
    'happy ending',
    'The end',
    'Happy ever after ...?',
    'Space is yellow',
    'Cursed curtains',
    'Ducks on holidays',
    'Carnival',
    'Circus magic',
    'The stranger name',
    'Téléportation',
];
const emptyStateTheme = {
    theme: '',
    list: initialList,
    tier: 0,
    updateCounter: 0,
    print: 0,
    tiers: [
        {
            words: 2,
            number: 1,
            timer: '05:00',
            content: '',
            wordsCount: 0,
        },
        {
            words: 20,
            number: 2,
            timer: '10:00',
            content: '',
            wordsCount: 0,
        },
        {
            words: 200,
            number: 3,
            timer: '15:00',
            content: '',
            wordsCount: 0,
        },
        {
            words: 2000,
            number: 4,
            timer: '60:00',
            content: '',
            wordsCount: 0,
        },
    ]
}
export const InitialState = (setState = (state: any) => { }) => ({
    ...emptyStateTheme,
    addTheme: (state: ContextType, theme: string) => {
        state.list = [theme];
        setState({ ...state });
    },
    setTheme: (state: ContextType, theme: string) => {
        const previous = state.theme
        state.theme = theme;
        addThemeToLocaStorageList(theme.replaceAll(' ', '_'), theme)
        state = state.getFromLocalStorage(state)

        updateThemeUrl(theme)
        if (state.theme !== previous) {
            state.tier = 0
        }

        setState({ ...state })
    },
    resetTheme: (state: ContextType) => {
        state = { ...state, ...emptyStateTheme }
        setState({ ...state })
    },
    setSelectedTier: (state: ContextType, tierIndex: number) => {
        state.tier = tierIndex;
        setState({ ...state });
    },
    updateState: (state: ContextType) => {
        setState({ ...state, updateCounter: state.updateCounter + 1 });
        state.saveToLocalStorage(state);
    },
    saveToLocalStorage: (state: ContextType) => {
        const { theme, tiers } = state
        const datas = JSON.stringify({ theme, tiers });
        if (state.theme) {
            localStorage.setItem(state.theme.replaceAll(' ', '_'), datas);
        } else {
            localStorage.setItem('temp', datas);
        }
    },
    getFromLocalStorage: (state: ContextType) => {
        let val;
        if (state.theme) {
            val = localStorage.getItem(state.theme.replaceAll(' ', '_'));
        } else {
            val = localStorage.getItem('temp');
        }
        console.log("getFromLocalStorage", state, val);
        if (val) {
            const { theme, tiers } = JSON.parse(val)
            console.log(JSON.parse(val));

            return { ...state, theme, tiers };
        }
        return state;
    },
});

export const ThemeContext = React.createContext<ContextType>(InitialState());
