import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react"
import CustomScroll from "react-custom-scroll";
import { ThemeContext } from "../context/theme.context";

export const OtherText = () => {
    const [open, setOpen] = useState(false)
    const list: { key: string, name: string }[] = JSON.parse(localStorage.getItem("themeList") || "[]")
    const state = useContext(ThemeContext);

    return (

        <div>
            {
                list.length > 0 &&
                <button onClick={() => setOpen(true)} className='text-xs py-2 font-bold'>
                    <FontAwesomeIcon icon={faFolder as IconProp} /> Retrouver vos précédents themes
                </button>
            }
            {!!open && (
                <div onClick={() => setOpen(false)} className='h-screen fixed top-0 left-0 right-0 bottom-0 p-4 flex flex-col justify-center z-50 items-center bg-slate-800/75 backdrop-blur-sm '>
                    <div className='max-w-2xl flex justify-center items-center' onClick={e => e.stopPropagation()}>

                        <div className="overflow-hidden h-96 p-2 max-w-sm flex flex-col ">
                            <CustomScroll heightRelativeToParent="100%">

                                <h2 className="font-light uppercase mb-4">Précédents Thèmes</h2>
                                {
                                    list.map(t => (
                                        <button
                                            key={t.key}
                                            className="my-1 rounded-full w-full py-1 px-8 border border-teal-600"
                                            onClick={() => {
                                                state.setTheme(state, t.name)
                                                setOpen(false)
                                            }}>
                                            {t.name}
                                        </button>
                                    ))
                                }


                            </CustomScroll>
                        </div>

                    </div>
                </div>

            )}
        </div>

    )
}