import React, { useEffect, useState } from 'react';
import './style.scss';
import './index.css';
import { Challenges } from './components/Challenges';
import { CurrentTier } from './components/CurrentTier';
import { LeftBlock } from './LeftBlock';
import { Form } from './components/Form';
import { PrintPage } from './PrintPage';
import { ContextType, Tier } from './context/models.context';
import { addThemeToLocaStorageList, InitialState, ThemeContext } from './context/theme.context';


export const ThemePage = () => {
  const [state, setState] = useState<ContextType>();
  const [formOpen, setFormOpen] = useState(false)
  const initParams = () => {
    const obj = {
      tier: 0,
      theme: '',
      currentTier: {},
    };

    const params = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );

    if ('tier' in params) {
      obj.tier = parseInt(params.tier) - 1;
      obj.currentTier = {
        ...obj.currentTier,
        number: parseInt(params.tier),
      };
    }
    if ('words' in params) {
      obj.currentTier = { ...obj.currentTier, words: parseInt(params.words) };
    }
    if ('timer' in params) {
      obj.currentTier = { ...obj.currentTier, timer: params.timer };
    }
    if ('theme' in params) {
      obj.theme = params.theme;
      addThemeToLocaStorageList(params.theme.replaceAll(' ', '_'), params.theme)
    }
    return obj;
  };
  const init = () => {
    const { theme, tier, currentTier } = initParams();
    setFormOpen(!!!theme)
    let initState = InitialState(setState)
    initState.tiers[tier] = { ...initState.tiers[tier], ...(currentTier as Partial<Tier>) };

    if (theme) {
      initState.theme = theme
      const newState = initState.getFromLocalStorage(initState);
      initState = { ...initState, ...(newState as any) };
    }
    setState(initState);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = { ...state } as ContextType;

  return (
    <>
      {state && (
        <ThemeContext.Provider value={value}>
          <div className='print:hidden'>
            {
              formOpen &&
              <Form onClose={() => setFormOpen(false)} />
            }
            <main className="app-container w-full h-screen text-teal-50">
              <LeftBlock />
              <section>
                <CurrentTier />
              </section>
              <section className="h-screen w-full overflow-y-auto p-5">
                <h2 className="text-2xl font-bold">Progression</h2>
                <Challenges />
              </section>
            </main>
          </div>
          <PrintPage />
        </ThemeContext.Provider>
      )}


    </>

  );
};
