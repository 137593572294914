import { CustomElement } from "..";
import { AlignmentType, Document, HeadingLevel, Packer, Paragraph, TextRun } from "docx";
import { Descendant } from "slate";
import { Tier } from "../../context/models.context";

enum ALIGN_MAP {
    center = AlignmentType.CENTER,
    left = AlignmentType.LEFT,
    justify = AlignmentType.BOTH,
    right = AlignmentType.RIGHT,
}

const saveFile = async (blob: Blob, filename: string) => {
    const a = document.createElement('a');
    a.download = filename;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
};

export const exportEditorToDocx = (editor: Descendant[]) => {
    console.log("exportEditorToDocx", editor);
    const editorDoc = editor.map((child) => {
        child = child as CustomElement
        return new Paragraph({
            // spread operator only work if bold and italics are named the same (s to italic)
            children: child.children.map(el => new TextRun({ ...el })),
            alignment: child.align ? ALIGN_MAP[child.align] as any as AlignmentType : AlignmentType.LEFT
        })

    })
    return editorDoc

}

export const exportTierToDocx = (tiers: Tier[], theme: string) => {
    const doc = new Document({
        title: theme,
        styles: {
            default: {
                document: {
                    run: {
                        font: "Calibri",
                    },
                    paragraph: {

                    }
                },

            },

        },
        sections: [
            {
                children: [
                    new Paragraph({
                        text: theme,
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER
                    }),
                    ...tiers.reduce((acc: Paragraph[], tier) => {
                        const block = [
                            new Paragraph({
                                text: "Echelon " + tier.number,
                                heading: HeadingLevel.HEADING_2,
                            })]
                        let editorDatas: Paragraph[] = []
                        if (tier.editor) {
                            editorDatas = exportEditorToDocx(tier.editor)
                        }
                        return [...acc, ...block, ...editorDatas]
                    }, [])]
            }]
    })

    Packer.toBlob(doc).then(blob => {
        console.log(blob);
        saveFile(blob, theme + ".docx");
        console.log("Document created successfully");
    });
}