// @refresh reset
import { useCallback, useContext, useEffect, useState } from "react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";
import { ThemeContext } from "./context/theme.context";
import { Element, Leaf } from "./miniEditor/MiniEditor";

export const PrintPage = () => {
    const { tiers, theme, updateCounter, print } = useContext(ThemeContext);
    const [all, setAll] = useState<any>([
        {
            type: 'paragraph',
            children: [{ text: "" }],
        }
    ])
    const [editor, setEditor] = useState(() => withHistory(withReact(createEditor())));

    useEffect(() => {
        let temp: any = [
            {
                type: 'paragraph',
                children: [{ text: "" }],
            }
        ]
        const tempTiers: typeof tiers = JSON.parse(JSON.stringify(tiers))
        console.log(tempTiers);

        tempTiers.forEach((t) => {
            if (!t.editor) {
                temp = [...temp, {
                    type: 'h2',
                    children: [{ text: `Echelon ${t.number}`, bold: true }],
                }]
                return
            }
            temp = [...temp, {
                type: 'h2',
                children: [{ text: `Echelon ${t.number}`, bold: true }],
            }, ...t.editor]

        })
        if (tempTiers.length === 0) {
            temp = [
                {
                    type: 'paragraph',
                    children: [{ text: "" }],
                }
            ]
        }

        // setAll(temp)
        editor.children = temp
        setEditor(editor)
        console.log(updateCounter, temp);


    }, [tiers, print])
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    // return <></>
    return (

        <div className="hidden h-auto print:block print:bg-white print:text-black	">
            <h1 className="text-3xl my-10">{theme} {updateCounter}</h1>
            <div className="h-auto overflow-hidden text-slate-300 flex-auto bg-zinc-900 rounded p-5 print:p-0 print:h-auto print:bg-slate-50 print:text-zinc-800">
                <div className="overflow-hidden  h-full text-left print:p-0 print:h-auto print:bg-slate-50 print:text-zinc-800">
                    <Slate
                        editor={editor}
                        value={all}
                        onChange={(val) => { }}
                    >
                        <Editable
                            readOnly={true}
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            spellCheck
                            className="h-full mr-4 mb-4 print:overflow-hidden"
                        />
                    </Slate>
                </div>
            </div>
        </div>
    )
}