import { useContext, useEffect, useState } from 'react';
import { Form } from './components/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileWord, faPenNib, faPlus, faPrint } from '@fortawesome/free-solid-svg-icons';
import { exportTierToDocx } from './miniEditor/export/docx.export';
import { OtherText } from './components/OtherText';
import { ThemeContext } from './context/theme.context';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { stat } from 'fs';


export const LeftBlock = () => {
  const [formOpen, setFormOpen] = useState(false)

  const state = useContext(ThemeContext);
  const [wc, setWc] = useState(0);
  useEffect(() => {
    setWc(
      state.tiers.reduce((acc, tier) => {
        return acc + tier.wordsCount;
      }, 0)
    );
  }, [state]);



  return (
    <section className="h-screen w-full flex flex-col justify-evenly items-center p-5">
      <div>
        <span className="font-light uppercase">Theme</span>
        <h1 onClick={() => setFormOpen(true)} className="first-letter:capitalize text-4xl font-medium">{state.theme}</h1>
        {
          formOpen && <Form onClose={() => setFormOpen(false)} />
        }
      </div>
      {/* <Timer /> */}
      <div className="flex flex-col w-44">
        {state.tiers.map((t, i) => (
          <button
            onClick={() => state.setSelectedTier(state, i)}
            key={'echelon-' + i}
            className={
              'p-1 my-3 rounded-full border border-teal-600 tier-btn' +
              (i === state.tier ? 'tier-current bg-teal-600' : '')
            }
          >
            Echelon {t.number}
          </button>
        ))}
      </div>
      <div> {wc} mots <FontAwesomeIcon icon={faPenNib as IconProp} /> </div>
      <div className='flex flex-col'>
        <button onClick={() => {
          state.print = state.print + 1
          state.updateState(state)
          setTimeout(() => {
            state.print = state.print + 1
            state.updateState(state)
          })
          setTimeout(() => { window.print(); }, 200)

        }}>
          <FontAwesomeIcon icon={faFilePdf as IconProp} /> <FontAwesomeIcon icon={faPrint as IconProp} /> PDF
        </button>
        <button onClick={() => { exportTierToDocx(state.tiers, state.theme) }}>
          <FontAwesomeIcon icon={faFileWord as IconProp} /> Docx
        </button>

      </div>
      {/* <button
        onClick={() => {
          console.log(state);
        }}
      >
        log state
      </button> */}
      <OtherText />
      <button className='text-xs py-2 font-bold rounded-full border-b border-teal-600 px-5' onClick={() => {
        setFormOpen(true)
        state.resetTheme(state)

      }}>Nouveau theme
        <FontAwesomeIcon className='ml-2' icon={faPlus as IconProp} />
      </button>
    </section>
  );
};
// onClick={state.setSelectedTier(i)}
