import { useContext, useState, useEffect } from 'react';
import { ThemeContext } from '../context/theme.context';
import { MiniEditor } from '../miniEditor/MiniEditor';
import { WordCount } from './WordCount';

export const CurrentTier = () => {
  const state = useContext(ThemeContext);
  const [tier, setTier] = useState(state.tiers[state.tier]);

  useEffect(() => {
    setTier(state.tiers[state.tier]);
  }, [state]);

  return (
    <div className="h-screen flex flex-col pt-5 pb-2">
      <div className="flex justify-between">
        <span className='bg-zinc-900 rounded-tr-md border-slate-600 border-l-2 px-4 py-1'>Echelon {tier.number}</span>
        <div className='bg-zinc-900  -mb-1 rounded-tl-full rounded-tr-full py-2 px-6'>
          <WordCount wc={tier.wordsCount} goal={tier.words} />
        </div>
      </div>
      <MiniEditor tierNumber={state.tier} />
      <p className='italic text-xs pt-2'>Vos textes sont sauvegardés <span className='font-bold'>localement</span > dans votre navigateur sur <span className='font-bold'>votre pc</span>, il n'y a aucune sauvegarde en ligne. </p>
    </div>
  );
};
