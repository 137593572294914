// import classNames from 'classnames';
import { useContext, useState } from 'react';
import { MiniEditor } from '../miniEditor/MiniEditor';
import { ThemeContext } from '../context/theme.context';
import { WordCount } from './WordCount';
import { Tier } from '../context/models.context';

export const Challenges = () => {
  const state = useContext(ThemeContext);
  const [showTier, setShowTier] = useState<Tier>();
  return (
    <div className="scroll-smooth">
      {!!!showTier &&
        state.tiers.map((t, i) => {
          const content = t.content || ""
          const arrayContent = content.split(" ")
          const extractLength = t.words > 30 ? 10 : t.words
          const shortContent = arrayContent.splice(0, extractLength).join(" ") + (arrayContent.length > 30 ? "..." : "")
          return (
            <div className="my-3" key={t.number}>
              <span className="font-bold">Echelon {t.number} - </span>
              <WordCount emphasis goal={t.words} wc={t.wordsCount} />

              <div className='relative min-h-[40px] flex flex-col justify-center bg-zinc-900/25'>
                {i === state.tier &&
                  <div className='absolute w-full h-full p-5 blur-0 backdrop-blur-sm rounded-md flex justify-center items-center'>
                    <p className="font-bold leading-4">En cours d'édition</p>
                  </div>}
                {t.wordsCount > 0 &&
                  <p className="font-normal p-2 overflow-hidden">{shortContent}</p>
                }
                {i !== state.tier && t.wordsCount === 0 && (
                  <p className="italic text-sm">- Pas commencé -</p>
                )}
              </div>
              {t.wordsCount > 0 && (
                <>

                  {t.wordsCount > arrayContent.length &&
                    <button
                      disabled={i === state.tier}
                      className="block my-3 m-auto border border-teal-600 rounded-full px-4 py-1"
                      onClick={() => setShowTier(t)}
                    >
                      Lire
                    </button>
                  }
                </>
              )}


            </div>
          );
        })}

      {!!showTier && (
        <div onClick={() => setShowTier(undefined)} className='h-screen fixed top-0 left-0 right-0 bottom-0 p-4 flex flex-col justify-center items-center bg-slate-800/75 backdrop-blur-sm '>
          <div className='h-min max-h-screen w-full max-w-2xl' onClick={e => e.stopPropagation()}>
            <button
              className="block my-3 mx-auto border border-teal-600 rounded-full px-4 py-1"
              onClick={() => setShowTier(undefined)}
            >
              Retourner aux échelons
            </button>
            <h3>Echelon {showTier.number}</h3>
            <MiniEditor readOnly tierNumber={showTier.number - 1} />
          </div>
        </div>
      )}
    </div>
  );
};
