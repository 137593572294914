import React from "react";
import "./App.css";
import { ThemePage } from "./theme/ThemePage";

function App() {
  return (
    <div className="w-screen h-screen App">
      <header className="App-header"></header>
      <ThemePage />
    </div>
  );
}

export default App;
