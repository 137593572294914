// @refresh reset
import { Editable, Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faItalic
} from '@fortawesome/free-solid-svg-icons';
import CustomScroll from 'react-custom-scroll';
import 'react-custom-scroll/dist/customScroll.css';
import { serialize } from './serializers';
import { createEditor, Descendant, Editor, Range, Element as SlateElement, Transforms } from 'slate';
import { CustomEditor, CustomElement, CustomText } from '.';
import classNames from 'classnames';
import { ThemeContext } from '../context/theme.context';
import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';

const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

interface MiniEditorProps {
  tierNumber: number,
  readOnly?: boolean
}

export const MiniEditor = ({ tierNumber, readOnly = false }: MiniEditorProps) => {
  const state = useContext(ThemeContext);
  const { updateState, tiers, updateCounter } = useContext(ThemeContext);

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  // Add the initial value when setting up our state.
  const [value, setValue] = useState<Descendant[]>(
    tiers[tierNumber]?.editor || [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ]
  );

  useEffect(() => {
    editor.children = value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  useEffect(() => {
    // can-t use setValue
    // https://github.com/ianstormtaylor/slate/issues/4612

    editor.children = tiers[tierNumber].editor || [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ];
    editor.selection = {
      anchor: { path: [0, 0], offset: 0 },
      focus: { path: [0, 0], offset: 0 },
    }
  }, [tierNumber, tiers]);

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { selection } = editor;

    // Default left/right behavior is unit:'character'.
    // This fails to distinguish between two cursor positions, such as
    // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
    // Here we modify the behavior to unit:'offset'.
    // This lets the user step into and out of the inline without stepping over characters.
    // You may wish to customize this further to only use unit:'offset' in specific cases.
    if (selection && Range.isCollapsed(selection)) {
      // const { nativeEvent } = event;
    }
  };

  return (
    <div className="h-5/6 overflow-hidden text-slate-300 flex-auto bg-zinc-900 rounded p-5
    print:p-0
    print:h-auto print:bg-slate-50 print:text-zinc-800">
      {
        !readOnly &&
        <div className="flex gap-2 justify-center mb-3 cursor-pointer">
          <MarkButton editor={editor} format="bold" icon={faBold} />
          <MarkButton editor={editor} format="italics" icon={faItalic} />
          <BlockButton editor={editor} format='left' icon={faAlignLeft} />
          <BlockButton editor={editor} format='center' icon={faAlignCenter} />
          <BlockButton editor={editor} format='right' icon={faAlignRight} />
          <BlockButton editor={editor} format='justify' icon={faAlignJustify} />
        </div>
      }
      {/* <div className="bg-zinc-700 flex gap-2 justify-center mb-3 cursor-pointer">    {updateCounter} {tiers[tierNumber].content}  </div> */}
      <div className="overflow-hidden
         h-[90%] text-left
        print:p-0
        print:h-auto print:bg-slate-50 print:text-zinc-800">
        <Slate
          editor={editor}
          value={value}
          onChange={(newValue) => {
            if (readOnly) return
            setValue(newValue);
            const plainText = serialize(newValue);
            const wordsCount = plainText.split(' ').filter((n) => n).length;
            const tier = { ...state.tiers[tierNumber] };
            if (wordsCount !== tier.wordsCount) {
              tier.wordsCount = wordsCount;
            }
            tier.content = plainText;
            tier.editor = newValue;
            const tempState = { ...state }
            tempState.tiers[tierNumber] = tier;
            updateState(tempState);
          }}
        >
          <CustomScroll className='print:overflow-hidden' heightRelativeToParent="100%">
            <Editable
              readOnly={readOnly}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              spellCheck
              onKeyDown={onKeyDown}
              className="h-full mr-4 mb-4 print:overflow-hidden"
            />
          </CustomScroll>
        </Slate>
      </div>
    </div>
  );
};

const toggleBlock = (editor: CustomEditor, format: string | keyof CustomElement["align"]) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )

  let newProperties: Partial<CustomElement> = {}
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format as keyof CustomElement["align"],
    }
  }
  Transforms.setNodes<CustomElement>(editor, newProperties)
}

const toggleMark = (editor: CustomEditor, format: keyof CustomText) => {
  const isActive = isMarkActive(editor, format)
  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor: CustomEditor, format: string, blockType: keyof SlateElement = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor: CustomEditor, format: keyof CustomText) => {
  if (editor.children.length > 0) {
    const marks: Partial<CustomText> | null = Editor.marks(editor)
    return marks ? marks[format] === true : false
  }
  return false

}

const Button = ({ active, icon, onMouseDown }: { active: boolean, icon: IconDefinition | any | IconProp; onMouseDown: () => void }) => {

  return (
    <button
      className={classNames("toolbar-btn flex justify-center items-center p-2 rounded", active ? "bg-slate-700" : "")}
      onMouseDown={(e) => {
        e.preventDefault()
        onMouseDown()
      }}>
      <FontAwesomeIcon role="button" icon={icon} />
    </button>
  )
}
const BlockButton = ({ format, icon, editor }: { format: string, icon: IconDefinition | IconProp | any, editor: CustomEditor }) => {
  return (
    <Button
      icon={icon}
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={() => {
        toggleBlock(editor, format)
      }}
    />
  )
}

const MarkButton = ({ format, icon, editor }: { format: keyof CustomText, icon: IconDefinition | IconProp | any, editor: CustomEditor }) => {
  return (
    <Button
      icon={icon}
      active={isMarkActive(editor, format)}
      onMouseDown={() => {
        toggleMark(editor, format)
      }}
    />
  )
}
export const Element = ({ attributes, children, element }: any) => {
  const style = { textAlign: element.align }
  switch (element.type) {
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'h2':
      return (
        <h2 className="mb-2 text-left text-xl">
          {children}
        </h2>
      )
    default:
      return (
        <p className='mb-2 editor-p' style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

export const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }
  if (leaf.italics) {
    children = <em>{children}</em>
  }

  return <span {...attributes}>{children}</span>
}