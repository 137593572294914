import classNames from 'classnames';

export const WordCount = ({
  wc,
  goal,
  emphasis = false,
}: {
  wc: number;
  goal?: number;
  emphasis?: boolean;
}) => {
  return (
    <span
      data-testid="wordcount"
      className={classNames('font-bold', {
        'text-lime-400': emphasis && goal && wc === goal,
        'text-amber-500': emphasis && goal && wc > goal,
        'text-stone-300': emphasis && goal && wc < goal,
      })}
    >
      {wc}{goal && <>/{goal}</>} mots
    </span>
  );
};
